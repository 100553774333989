import logo from '../ResiPeak.svg';

export default function Home() {
    return (
        <div>
            <div class="hero min-h-screen" style={{backgroundImage: "url(https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)"}}>
                <div class="hero-overlay bg-opacity-40"></div>
                <div class="hero-content text-center text-white">
                    <div class="max-w-lg">
                        <h1 class="text-5xl font-bold pb-8">The New Way of Finding and Listing Homes</h1>
                        <button class="btn btn-primary m-2">For Agents</button>
                        <button class="btn btn-secondary m-2">For Buyers and Sellers</button>
                    </div>
                </div>
            </div> 
            <div class="max-w-4xl mx-auto">
                <h1 class="pt-24 px-12 text-3xl font-bold" id="about">What exactly is ResiPeak?</h1>
                <p class="px-12 pb-12 pt-6 text-xl">ResiPeak is providing a new way for real estate professionals and their clients to work together to find the right house and list their house.<br /><br />We are creating collaboration between clients and agents allowing for seemless communications and up-to date home searches for everyone. Unlike the MLS, all of our listing data is public and we do not regionalize our dataset. You can search anywhere at anytime no matter who you are.<br /><br />We understand that everyone needs paid, here at ResiPeak we still allow for sellers to offer commissions to buyers agents in several forms including a fixed-price, percentage, and concession.</p>
                <h1 class="px-12 pt-12 text-3xl font-semibold text-black pb-6">How does ResiPeak work for me?</h1>
                <div role="tablist" class="tabs tabs-boxed bg-primary-content tabs-lg px-8 mx-12 py-8">

                    <input type="radio" name="tabs" role="tab" class="tab font-semibold" aria-label="Agents" id="forAgents" />
                    <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6">
                        <h2 class="text-xl font-bold"><img src={logo} class="h-10 inline pb-1" /> for Agents</h2>
                        <p><br />ResiPeak is creating an easy environment for agents to find and list real property. Our forward thinking design allows you to easily find what information you need and provides a seemless experience posting property.<br /><br />For sellers agents, you can manage your listings in the easy-to-use dashboard and allow your clients to submit edit suggestions and add details about their home. Use our tools to create the best SEO possible for your listings to show up in our search and on Google.<br /><br />For our buyer agents, you can create "collections" and allow your clients to add to them. Want me to show you a house? Add it to the collection. You can also directly send properties to your clients using just a link instead of having to send a Zillow link or saving the page as an ugly PDF.</p>
                        <br/><a class="link link-primary">Learn more about how ResiPeak can work for you as an agent →</a>
                    </div>

                    <input type="radio" name="tabs" role="tab" class="tab font-semibold" aria-label="Sellers" id="forSellers" />
                    <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6">
                        <h2 class="text-xl font-bold"><img src={logo} class="h-10 inline pb-1" /> for Sellers</h2>
                        <p><br />Why should I tell my agent to use ResiPeak? Well it's surprising they're not already. ResiPeak is where buyers and buyers agents are flocking to find new homes. With new restrictions imposed against the MLS in its anti-trust lawsuits, many people are going to new services.<br /><br />We also allow anyone to search for properties. In modern times many buyers end up sending their agents properties they want to find. Zillow displays old data and the MLS is private, have your agent post your property on ResiPeak to be seen by the buyers and to have updated information about your property on the internet.</p>
                        <br/><a class="link link-primary">Learn more about how ResiPeak can work for you as a seller →</a>
                    </div>

                    <input type="radio" name="tabs" role="tab" class="tab font-semibold" aria-label="Buyers" id="forBuyers" />
                    <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6">
                        <h2 class="text-xl font-bold"><img src={logo} class="h-10 inline pb-1" /> for Buyers</h2>
                        <p><br />Just do it! Go search for a home! We provide the most up-to date data on real property on the internet. Select your city and input a few criteria and hit find! ResiPeak provides the most up-to date information because real estate agents use us. The MLS likes to gatekeep information from the public, not us. Find a property you like? We know it is for sale, send it to your agent.</p>
                        <br/><a class="link link-primary">Learn more about how ResiPeak can work for you as a buyer →</a>
                    </div>

                    <input type="radio" name="tabs" role="tab" class="tab font-semibold" aria-label="Brokers" id="forBrokers" />
                    <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6">
                        <h2 class="text-xl font-bold"><img src={logo} class="h-10 inline pb-1" /> for Brokers</h2>
                        <p><br />Run a brokerage? You have came to the right spot.<br /><br />The way agents find, sell, and market homes is changing. We want to take some weight off your agents shoulders and make sure they are performing the best they can for you. We provide many tools for brokers including discounted and free rates for agents, tools for creating custom search webpages, and a CRM for your agents.<br /><br />Reach out to us with a little bit of information and we would love to get back to you with use-cases, pricing, and much more!</p>
                        <br/><a class="link link-primary">Learn more about how ResiPeak can work for you and your agents →</a>
                    </div>
                </div>
                <div class="flex flex-col gap-10 p-10 py-24">
                    <h1 class="text-3xl font-bold">
                        <i class="fa-solid fa-circle-question text-primary"></i>
                        Frequently Asked Questions
                    </h1>

                    <div class="grid grid-cols-1 sm:grid-cols-2 gap-8">
                        <div class="flex flex-col gap-2">
                            <h2 class="text-lg font-bold text-primary">
                                Can anyone access ResiPeak?
                            </h2>

                            <span>
                                Yes, we make ResiPeak accessible to agents and the public. This removed unneeded confusion and allows for easier communication unlike other companies and the MLS.
                            </span>
                        </div>

                        <div class="flex flex-col gap-2">
                        <h2 class="text-lg font-bold text-primary">
                                Can we post buyer commissions?
                            </h2>

                            <span>
                                Yes you can, sellers can offer commissions for buyers agents in the form of a fixed-price, a percentage, or a concession. The anti-trust case against the NAR does NOT affect us.
                            </span>
                        </div>

                        <div class="flex flex-col">
                        <h2 class="text-lg font-bold text-primary">
                                How expensive is ResiPeak?
                            </h2>

                            <span>
                                ResiPeak costs $30 per listing which should be paid by the real estate agent. As an agent you can buy a subscription and get unlimited listings. Browsing properties and listings is free forever.
                            </span>
                        </div>

                        <div class="flex flex-col">
                        <h2 class="text-lg font-bold text-primary">
                                When does ResiPeak go live?
                            </h2>

                            <span>
                                We are trying our best to go live as soon as possible. Use the email form below to get updates on progress and when we open the door for the public.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
                <div class="flex flex-col items-center gap-6 py-24 bg-gray-800 text-base-300">
                    <div class="flex flex-col gap-2 items-center text-center">
                        <h1 class="text-2xl font-bold">
                            <i class="fa-solid fa-newspaper text-secondary"></i>
                            Signup to get updates from ResiPeak
                        </h1>

                        <span>Get notified when we go live, learn about how real estate is changing, and get tips from our team!</span>
                    </div>

                    <form class="flex gap-2 text-black pageclip-form" method="post" action="https://send.pageclip.co/xK2VkMpGVwU4ym7pP7EeAXbnjgCIMlps/landing-page" id='signup'>
                    <input type="email" name="email" class="input input-bordered" placeholder="Enter your email" required />
                    <input type="text" name="name" class="input input-bordered" placeholder="Enter your name" required />

                        <button class="btn btn-primary pageclip-form__submit" type="submit">Subscribe</button>
                    </form>
                </div>
        </div>
    )
}
import logo from '../ResiPeak.svg';

export default function Footer() {
    return (
        <footer class="footer p-10 bg-base-200 text-base-content">
            <aside>
                <img class='h-10' src={logo} />
                <p>&copy; ResiPeak 2024.<br />Providing the best real estate experience since 2024.</p>
            </aside> 
            <nav>
                <h6 class="footer-title">ResiPeak for</h6> 
                <a class="link link-hover">Agents</a>
                <a class="link link-hover">Sellers</a>
                <a class="link link-hover">Buyers</a>
                <a class="link link-hover">Brokers</a>
            </nav> 
            <nav>
                <h6 class="footer-title">Company</h6> 
                <a class="link link-hover">About us</a>
                <a class="link link-hover">Contact</a>
                <a class="link link-hover">Press kit</a>
            </nav> 
            <nav>
                <h6 class="footer-title">Legal</h6> 
                <a class="link link-hover">Terms of use</a>
                <a class="link link-hover">Privacy policy</a>
                <a class="link link-hover">Cookie policy</a>
            </nav>
        </footer>
    )
}
import logo from '../ResiPeak.svg';

export default function Navbar() {
    return (
        <div class="navbar bg-base-100 fixed z-50">
        <div class="navbar-start">
          <div class="dropdown">
            <div tabindex="0" role="button" class="btn btn-ghost md:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
            </div>
            <ul tabindex="0" class="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
              <li><a href="#about">About ResiPeak</a></li>
              <li>
                <li>What is ResiPeak?</li>
                <ul class="p-2">
                <li><a href="#about">ResiPeak Explained</a></li>
                  <li><a>for Agents</a></li>
                  <li><a>for Sellers</a></li>
                  <li><a>for Buyers</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <a href="/" class="text-xl"><img class='h-10' src={logo} /></a>
        </div>
        <div class="navbar-center hidden md:flex">
          <ul class="menu menu-horizontal px-1">
            <li><a href="#about">About ResiPeak</a></li>
            <li>
              <details>
                <summary>What is ResiPeak</summary>
                <ul class="p-2 w-48">
                  <li><a href="#about">ResiPeak Explained</a></li>
                  <li><a>for Agents</a></li>
                  <li><a>for Sellers</a></li>
                  <li><a>for Buyers</a></li>
                </ul>
              </details>
            </li>
          </ul>
        </div>
        <div class="navbar-end">
          <a class="btn btn-primary" href="#signup">Signup for updates now!</a>
        </div>
      </div>
    )
  }
  